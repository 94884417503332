/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import loadable from "@loadable/component"
import HeaderTwo from "./HeaderTwo/HeaderTwo";
import { useStaticQuery, graphql } from "gatsby"
// import Footer from "./Footer/Footer";
import "../styles/main.scss";
import HeaderLogoCenterTwo from "./HeaderLogoCenterTwo/HeaderLogoCenterTwo";

const Footer = loadable(() => import("./Footer/Footer"));

const LayoutTwo = ({ children, popularSearch, tag, footerContact, customClass, enquire }) => {

  const data = useStaticQuery(graphql`
  query {
    strapiThemeConfig {
      theme_style
    }
    site {
      siteMetadata {
        headerCenter
      }
    }
  }
`);

  const theme_style = data.strapiThemeConfig?.theme_style;
  const headerCenter = data?.site?.siteMetadata?.headerCenter;

  return (
    <div className={customClass}>
      {headerCenter ?
        <HeaderLogoCenterTwo tag={tag} headerDubai={theme_style === "dubai" ? "header-dubai" : ""} />
        :
        <HeaderTwo tag={tag} headerDubai={theme_style === "dubai" ? "header-dubai" : ""} />
      }
      <main>{children}</main>
      <Footer popularSearch={popularSearch} enquire={enquire} footerContact={footerContact} footerDubai={theme_style === "dubai" ? "footer-dubai" : ""} />
    </div>
  )
}

export default LayoutTwo